a {
  text-decoration: none;
  color: #000;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  background-color: transparent;
  text-align: center;
}

.container:hover h1,
.container:hover p {
  filter: blur(5px);
}

p {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
}

.cursor {
  filter: hue-rotate(90deg) saturate(2) blur(10px);
  transform: scaleX(2) scaleY(0.5);
  opacity: 0;
  width: 100%;
  height: auto;
  max-width: 650px;
  box-sizing: border-box;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.container:hover .cursor {
  opacity: 1;
  filter: hue-rotate(0deg) saturate(1);
}

.container:active .cursor {
  opacity: 1;
  filter: hue-rotate(240deg) saturate(2) blur(10px);
}

.cursor:hover {
  filter: hue-rotate(0deg) saturate(1);
}

.cursor:active {
  filter: hue-rotate(240deg) saturate(2) blur(10px);
}
